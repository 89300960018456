import React from 'react';

import { LoggedUserFormsContext } from './LoggedUserFormsContext.js';

export const withLoggedUserFormsContext = WrappedComponent => props => {

    const { locale,
        headerInfoText,
        breadCrumbNavigationTitle,
        breadCrumbNavigationText,
        topMenuButtonDisabled,
        topMenuButtonHandler,
        onBreadCrumbNavigationChange,
        onTopMenuButtonDisabledChange,
        addTopMenuButtonHandler,
        removeTopMenuButtonHandler } = React.useContext(LoggedUserFormsContext);

    return (
        <WrappedComponent
            {...props}
            locale                                     = {locale}
            headerInfoText                             = {headerInfoText}
            breadCrumbNavigationTitle                  = {breadCrumbNavigationTitle}
            breadCrumbNavigationText                   = {breadCrumbNavigationText}
            topMenuButtonDisabled                      = {topMenuButtonDisabled}
            topMenuButtonHandler                       = {topMenuButtonHandler}
            onBreadCrumbNavigationChange               = {onBreadCrumbNavigationChange}
            onTopMenuButtonDisabledChange              = {onTopMenuButtonDisabledChange}
            addTopMenuButtonHandler                    = {addTopMenuButtonHandler}
            removeTopMenuButtonHandler                 = {removeTopMenuButtonHandler}
        />
    );
};