import { formatNumber } from '@progress/kendo-intl';

import DateTimeRtns from './DateTimeRtns.js';
import Constants from 'Constants.js';

export default class RDPUtils {

    // Vraci kvartal ktery mohu prave ted (podle datumu) editovat
    static getEditQuarter() {
        let quarter = DateTimeRtns.GetQuarter(new Date()) - 1;        
        if (quarter === 0) quarter = 4;
        return quarter;
    }


    // Vraci rok ktery mohu prave ted (podle datumu) editovat
    static getEditYear() {
        let year = new Date().getFullYear();
        let quarter = RDPUtils.getEditQuarter();
        
        if (quarter === 4) {
            year = (year - 1);
        }

        return year;
    }

    // Vrací seřazený seznam všech kvartálu statistiky RDP
    static getAllRDPQuarters() {

        let result = [];
        let index = 0; 

        for (let year = Constants.rdpFirstYearOfRDP; year <= RDPUtils.getEditYear(); year++) {
            for (let quarter = (year === Constants.rdpFirstYearOfRDP ? Constants.rdpFirstQuarterOfRDP : 1);
                quarter <= (year === RDPUtils.getEditYear() ? RDPUtils.getEditQuarter() : 4);
                quarter++) {
                result.push({ year, quarter, index : index++ });
            }
        }

        return result;
    }   


    static formatNumber(number, locale) {
        return formatNumber(number, "0.##", locale)
    }

    // Rozšíří seznam hodnot o null položku
    static withNull(list) {
        return [{ value: null, name: ''}, ...list];
    }

    // Vrací pole ano / ne
    static yesNoList() {
        return [{ value: true,  name: RDPUtils.yesNoString(true) },
                { value: false, name: RDPUtils.yesNoString(false) }];
    }

    // Ano / Ne vraci jako text
    static yesNoString(b) {
        switch (b) {
            case true: return "Ano";
            case false: return "Ne";
            default: return "";
        }
    }

    // Vraci pohlaví jako text
    static sexToString(sex) {
        switch (sex) {
            case Constants.dbmMale: return 'Muž';
            case Constants.dbmFemale: return 'Žena';
            default: return '';
        }
    }

    // Vrací pole všech možných pohlaví
    static sexList() {
        return [{ value: Constants.dbmMale, name: RDPUtils.sexToString(Constants.dbmMale) },
                { value: Constants.dbmFemale, name: RDPUtils.sexToString(Constants.dbmFemale) }];        
    }

    // Vrací pole všech možných událostí
    static courseEventList() {
        return [
            { value: Constants.dbmStartPor       ,      name: RDPUtils.courseEventToString(Constants.dbmStartPor       ) },
            { value: Constants.dbmStartRrtTpl    ,      name: RDPUtils.courseEventToString(Constants.dbmStartRrtTpl    ) },
            { value: Constants.dbmStartRrtHD     ,      name: RDPUtils.courseEventToString(Constants.dbmStartRrtHD     ) },
            { value: Constants.dbmStartRrtPD     ,      name: RDPUtils.courseEventToString(Constants.dbmStartRrtPD     ) },
            { value: Constants.dbmMethodChangeHD ,      name: RDPUtils.courseEventToString(Constants.dbmMethodChangeHD ) },
            { value: Constants.dbmMethodChangePD ,      name: RDPUtils.courseEventToString(Constants.dbmMethodChangePD ) },
            { value: Constants.dbmMethodChangeTpl,      name: RDPUtils.courseEventToString(Constants.dbmMethodChangeTpl) },
            { value: Constants.dbmExitus         ,      name: RDPUtils.courseEventToString(Constants.dbmExitus         ) },
            { value: Constants.dbmRefusal        ,      name: RDPUtils.courseEventToString(Constants.dbmRefusal        ) },
            { value: Constants.dbmFunctionReturn,       name: RDPUtils.courseEventToString(Constants.dbmFunctionReturn) },
            { value: Constants.dbmPalliativeTreatment,  name: RDPUtils.courseEventToString(Constants.dbmPalliativeTreatment) },
            { value: Constants.dbmHHDStart,             name: RDPUtils.courseEventToString(Constants.dbmHHDStart) },
            { value: Constants.dbmHHDEnd,               name: RDPUtils.courseEventToString(Constants.dbmHHDEnd) },
        ];
    }

    // Vraci událost jako text
    static courseEventToString(event) {        
        switch (event) {            
            case Constants.dbmStartPor              : return 'Vstup do poradny';
            case Constants.dbmStartRrtTpl           : return 'Vstup do RRT - transplantace';
            case Constants.dbmStartRrtHD            : return 'Vstup do RRT - HD';
            case Constants.dbmStartRrtPD            : return 'Vstup do RRT - PD';
            case Constants.dbmMethodChangeHD        : return 'Změna metody - HD';
            case Constants.dbmMethodChangePD        : return 'Změna metody - PD';
            case Constants.dbmMethodChangeTpl       : return 'Změna metody - transplantace';
            case Constants.dbmExitus                : return 'Exitus';
            case Constants.dbmRefusal               : return 'Odmítnutí léčby pacientem';
            case Constants.dbmFunctionReturn        : return 'Návrat funkcí';
            case Constants.dbmPalliativeTreatment   : return 'Paliativní léčba';
            case Constants.dbmHHDStart              : return 'Zahájení domácí dialýzy';
            case Constants.dbmHHDEnd                : return 'Ukončení domácí dialýzy';
            default: return '';
        }
    }

    static isHomeDial(courses, quarter, year) {
        const lastDayOfQuarter = DateTimeRtns.GetLastDayOfQuarter(quarter, year);
        const hhdEvents = courses.filter(w => w.date <= lastDayOfQuarter && (w.event === Constants.dbmHHDStart || w.event === Constants.dbmHHDEnd));
        return hhdEvents.length > 0 && hhdEvents.pop().event === Constants.dbmHHDStart;                                                        
    }

    // Vrací pole všech možných událostí
    static wlCourseEventList() {
        return [
            { value: Constants.dbmIncluded  , name: RDPUtils.wlCourseEventToString(Constants.dbmIncluded  ) },
            { value: Constants.dbmRemoveTemp, name: RDPUtils.wlCourseEventToString(Constants.dbmRemoveTemp) },
            { value: Constants.dbmRemoveDoc , name: RDPUtils.wlCourseEventToString(Constants.dbmRemoveDoc ) },
            { value: Constants.dbmRemoveDen , name: RDPUtils.wlCourseEventToString(Constants.dbmRemoveDen ) },
        ];
    }

    // Vraci událost jako text
    static wlCourseEventToString(event) {
        switch (event) {
            case Constants.dbmIncluded  : return 'Zařazen';
            case Constants.dbmRemoveTemp: return 'Dočasně vyřazen';
            case Constants.dbmRemoveDoc : return 'Vyřazen - lékařské hledisko';
            case Constants.dbmRemoveDen : return 'Vyřazen - odmítnul';
            default: return '';
        }
    }

    // Vrací pole všech možných rrt method
    static rrtMethodList() {
        return [
            { value: Constants.dbmCAPD_NAPD , name: RDPUtils.rrtMethodToString(Constants.dbmCAPD_NAPD ) },
            { value: Constants.dbmCAPD      , name: RDPUtils.rrtMethodToString(Constants.dbmCAPD      ) },
            { value: Constants.dbmCCPD      , name: RDPUtils.rrtMethodToString(Constants.dbmCCPD      ) },
            { value: Constants.dbmHD        , name: RDPUtils.rrtMethodToString(Constants.dbmHD        ) },
            { value: Constants.dbmHF_ON     , name: RDPUtils.rrtMethodToString(Constants.dbmHF_ON     ) },
            { value: Constants.dbmHF_OFF    , name: RDPUtils.rrtMethodToString(Constants.dbmHF_OFF    ) },
            { value: Constants.dbmHDF_ON    , name: RDPUtils.rrtMethodToString(Constants.dbmHDF_ON    ) },
            { value: Constants.dbmHDF_ON_PRE, name: RDPUtils.rrtMethodToString(Constants.dbmHDF_ON_PRE) },
            { value: Constants.dbmHDF_ON_POS, name: RDPUtils.rrtMethodToString(Constants.dbmHDF_ON_POS) },
            { value: Constants.dbmHDF_OFF   , name: RDPUtils.rrtMethodToString(Constants.dbmHDF_OFF   ) },
            { value: Constants.dbmHDF_OFF_PR, name: RDPUtils.rrtMethodToString(Constants.dbmHDF_OFF_PR) },
            { value: Constants.dbmHDF_OFF_PO, name: RDPUtils.rrtMethodToString(Constants.dbmHDF_OFF_PO) },
            { value: Constants.dbmAFB       , name: RDPUtils.rrtMethodToString(Constants.dbmAFB       ) },
            { value: Constants.dbmPFD       , name: RDPUtils.rrtMethodToString(Constants.dbmPFD       ) },
            { value: Constants.dbmPF        , name: RDPUtils.rrtMethodToString(Constants.dbmPF        ) },
            { value: Constants.dbmHP        , name: RDPUtils.rrtMethodToString(Constants.dbmHP        ) },
            { value: Constants.dbmHD_HP     , name: RDPUtils.rrtMethodToString(Constants.dbmHD_HP     ) },
            { value: Constants.dbmHDF_MIXED , name: RDPUtils.rrtMethodToString(Constants.dbmHDF_MIXED ) },
        ];
    }

    // Vraci událost jako text
    static rrtMethodToString(method) {
        switch (method) {
            case Constants.dbmCAPD_NAPD     : return 'CAPD+NAPD';
            case Constants.dbmCAPD          : return 'CAPD';
            case Constants.dbmCCPD          : return 'CCPD';
            case Constants.dbmHD            : return 'HD';
            case Constants.dbmHF_ON         : return 'HF-ON';
            case Constants.dbmHF_OFF        : return 'HF-OFF';
            case Constants.dbmHDF_ON        : return 'HDF-ON';
            case Constants.dbmHDF_ON_PRE    : return 'HDF-ON pre.';
            case Constants.dbmHDF_ON_POS    : return 'HDF-ON post.';
            case Constants.dbmHDF_OFF       : return 'HDF-OFF';
            case Constants.dbmHDF_OFF_PR    : return 'HDF-OFF pre.';
            case Constants.dbmHDF_OFF_PO    : return 'HDF-OFF post.';
            case Constants.dbmAFB           : return 'AFB';
            case Constants.dbmPFD           : return 'PFD';
            case Constants.dbmPF            : return 'PF';
            case Constants.dbmHP            : return 'HP';
            case Constants.dbmHD_HP         : return 'HD+HP';
            case Constants.dbmHDF_MIXED     : return 'HDF-Mixed';
            default: return '';
        }
    }

    // Vrací pole všech možných typů cévního přístupu
    static vascularAccessTypeList() {
        return [
            { value: Constants.dbmVatPISTEL, name: RDPUtils.vascularAccessTypeToString(Constants.dbmVatPISTEL) },
            { value: Constants.dbmVatRC_AV, name: RDPUtils.vascularAccessTypeToString(Constants.dbmVatRC_AV) },
            { value: Constants.dbmVatBC_AV, name: RDPUtils.vascularAccessTypeToString(Constants.dbmVatBC_AV) },
            { value: Constants.dbmVatUC_AV, name: RDPUtils.vascularAccessTypeToString(Constants.dbmVatUC_AV) },
            { value: Constants.dbmVatUB_AV, name: RDPUtils.vascularAccessTypeToString(Constants.dbmVatUB_AV) },
            { value: Constants.dbmVatKANYLA, name: RDPUtils.vascularAccessTypeToString(Constants.dbmVatKANYLA) },
            { value: Constants.dbmVatPIST_KANIL, name: RDPUtils.vascularAccessTypeToString(Constants.dbmVatPIST_KANIL) },
            { value: Constants.dbmVatKATETR, name: RDPUtils.vascularAccessTypeToString(Constants.dbmVatKATETR) },
            { value: Constants.dbmVatOTHER     , name: RDPUtils.vascularAccessTypeToString(Constants.dbmVatOTHER     ) },
        ];
    }
    
    // Vraci typ cévního přístupu jako text
    static vascularAccessTypeToString(method) {
        switch (method) {
            case Constants.dbmVatPISTEL    : return 'AV Píštěl';
            case Constants.dbmVatRC_AV     : return 'RC AV';
            case Constants.dbmVatBC_AV     : return 'BC AV';
            case Constants.dbmVatUC_AV     : return 'UC AV';
            case Constants.dbmVatUB_AV     : return 'UB AV';
            case Constants.dbmVatKANYLA    : return 'Kanyla';
            case Constants.dbmVatPIST_KANIL: return 'Píšt. + Kan.';
            case Constants.dbmVatKATETR    : return 'Katetr PD';
            case Constants.dbmVatOTHER     : return 'Jiný';
            default: return '';
        }
    }
    
    // Vrací pole všech možných lokalizací cévního přístupu
    static vascularAccessLocalizationList() {
        return [
            { value: Constants.dbmValLHK      , name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValLHK      ) },
            { value: Constants.dbmValPHK      , name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValPHK      ) },
            { value: Constants.dbmValDK       , name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValDK       ) },
            { value: Constants.dbmValV_JUGUL_P, name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValV_JUGUL_P) },
            { value: Constants.dbmValV_JUGUL_L, name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValV_JUGUL_L) },
            { value: Constants.dbmValV_SUBCL_P, name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValV_SUBCL_P) },
            { value: Constants.dbmValV_SUBCL_L, name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValV_SUBCL_L) },
            { value: Constants.dbmValV_FEMOR_P, name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValV_FEMOR_P) },
            { value: Constants.dbmValV_FEMOR_L, name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValV_FEMOR_L) },
            { value: Constants.dbmValOTHER    , name: RDPUtils.vascularAccessLocalizationToString(Constants.dbmValOTHER    ) },
        ];
    }

    // Vraci lokalizaci cévního přístupu jako text
    static vascularAccessLocalizationToString(method) {
        switch (method) {
            case Constants.dbmValLHK      : return 'LHK';
            case Constants.dbmValPHK      : return 'PHK';
            case Constants.dbmValDK       : return 'DK';
            case Constants.dbmValV_JUGUL_P: return 'V. Jugul. P';
            case Constants.dbmValV_JUGUL_L: return 'V. Jugul. L';
            case Constants.dbmValV_SUBCL_P: return 'V. Subcl. P';
            case Constants.dbmValV_SUBCL_L: return 'V. Subcl. L';
            case Constants.dbmValV_FEMOR_P: return 'V. Femor. P';
            case Constants.dbmValV_FEMOR_L: return 'V. Femor. L';
            case Constants.dbmValOTHER    : return 'Jiný';
            default: return '';
        }
    }

    // Vrací pole všech možných očkování
    static inoculationList() {
        return [
            { value: Constants.dbmInocFLU    , name: RDPUtils.inoculationTypeToString(Constants.dbmInocFLU    ) },
            { value: Constants.dbmInocTBC    , name: RDPUtils.inoculationTypeToString(Constants.dbmInocTBC    ) },
            { value: Constants.dbmInocTETANUS, name: RDPUtils.inoculationTypeToString(Constants.dbmInocTETANUS) },
            { value: Constants.dbmInocHEP_B  , name: RDPUtils.inoculationTypeToString(Constants.dbmInocHEP_B  ) },
            { value: Constants.dbmInocOTHER  , name: RDPUtils.inoculationTypeToString(Constants.dbmInocOTHER  ) },            
        ];
    }

    // Vraci typ očkování jako text
    static inoculationTypeToString(method) {
        switch (method) {
            case Constants.dbmInocFLU    : return 'Chřipka';
            case Constants.dbmInocTBC    : return 'TBC';
            case Constants.dbmInocTETANUS: return 'Tetanus';
            case Constants.dbmInocHEP_B  : return 'Hepatitis B';
            case Constants.dbmInocOTHER  : return 'Ostatní';
            
            default: return '';
        }
    }

    // Vrací pole všech možných dialyzátorů
    static dialTypeList() {
        return [
            { value: Constants.dbmDialTypeHF, name: RDPUtils.dialTypeToString(Constants.dbmDialTypeHF) },
            { value: Constants.dbmDialTypeLF, name: RDPUtils.dialTypeToString(Constants.dbmDialTypeLF) },            
        ];
    }

    // Vraci typ dialyzátoru jako text
    static dialTypeToString(method) {
        switch (method) {
            case Constants.dbmDialTypeHF: return 'HF';
            case Constants.dbmDialTypeLF: return 'LF';            
            default: return '';
        }
    }

    // Vrací pole všech možných dialyzačních membrán
    static dialMemTypeList() {
        return [
            { value: Constants.dbmDialMemTypeREG_CEL   , name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypeREG_CEL   ) },
            { value: Constants.dbmDialMemTypeMOD_CEL   , name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypeMOD_CEL   ) },
            { value: Constants.dbmDialMemTypeREG_CEL_MO, name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypeREG_CEL_MO) },
            { value: Constants.dbmDialMemTypeACETAT    , name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypeACETAT    ) },
            { value: Constants.dbmDialMemTypeDIACETAT  , name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypeDIACETAT  ) },
            { value: Constants.dbmDialMemTypeTRICETAT  , name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypeTRICETAT  ) },
            { value: Constants.dbmDialMemTypePOLYAKRYLO, name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypePOLYAKRYLO) },
            { value: Constants.dbmDialMemTypePOLYMETYLM, name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypePOLYMETYLM) },
            { value: Constants.dbmDialMemTypeKOPOLYMER , name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypeKOPOLYMER ) },
            { value: Constants.dbmDialMemTypePOLYKARBON, name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypePOLYKARBON) },
            { value: Constants.dbmDialMemTypePOLYAMID  , name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypePOLYAMID  ) },
            { value: Constants.dbmDialMemTypePOLYSULFON, name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypePOLYSULFON) },
            { value: Constants.dbmDialMemTypePOLYARYLET, name: RDPUtils.dialMemTypeToString(Constants.dbmDialMemTypePOLYARYLET) },
        ];
    }

    // Vraci typ dialyzační membrány jako text
    static dialMemTypeToString(method) {
        switch (method) {
            case Constants.dbmDialMemTypeREG_CEL   : return 'Regenerovaná celulóza';
            case Constants.dbmDialMemTypeMOD_CEL   : return 'Modifikovaná regenerovaná celulóza';
            case Constants.dbmDialMemTypeREG_CEL_MO: return 'Regenerovaná celulóza s povrch. úpravou';
            case Constants.dbmDialMemTypeACETAT    : return 'Acetát celulózy';
            case Constants.dbmDialMemTypeDIACETAT  : return 'Diacetát celulózy';
            case Constants.dbmDialMemTypeTRICETAT  : return 'Triacetát celulózy';
            case Constants.dbmDialMemTypePOLYAKRYLO: return 'Polyakrylonitril';
            case Constants.dbmDialMemTypePOLYMETYLM: return 'Polymetylmetakrylát';
            case Constants.dbmDialMemTypeKOPOLYMER : return 'Kopolymer etylénvinylakoholu';
            case Constants.dbmDialMemTypePOLYKARBON: return 'Polykarbonát/polyeter';
            case Constants.dbmDialMemTypePOLYAMID  : return 'Polyamid';
            case Constants.dbmDialMemTypePOLYSULFON: return 'Polysulfon';
            case Constants.dbmDialMemTypePOLYARYLET: return 'Polyaryletersulfon';            

            default: return '';
        }
    }

    // Vrací pole všech možných PD metod
    static pdMethodTypeList() {
        return [
            { value: Constants.dbmPdMethodCAPD_NAPD, name: RDPUtils.dialMemTypeToString(Constants.dbmPdMethodCAPD_NAPD) },
            { value: Constants.dbmPdMethodCAPD     , name: RDPUtils.dialMemTypeToString(Constants.dbmPdMethodCAPD     ) },
            { value: Constants.dbmPdMethodCCPD     , name: RDPUtils.dialMemTypeToString(Constants.dbmPdMethodCCPD     ) },
        ];
    }

    // Vraci typ PD metody jako text
    static pdMethodTypeToString(method) {
        switch (method) {
            case Constants.dbmPdMethodCAPD_NAPD : return 'CAPD+NAPD';
            case Constants.dbmPdMethodCAPD      : return 'CAPD';
            case Constants.dbmPdMethodCCPD      : return 'CCPD';
            default: return '';
        }
    }

    // 
    static negPosList() {
        return [
            { value: Constants.dbmNegative, name: RDPUtils.negPosToString(Constants.dbmNegative ) },
            { value: Constants.dbmPositive, name: RDPUtils.negPosToString(Constants.dbmPositive ) },
            { value: Constants.dbmNoExamine, name: RDPUtils.negPosToString(Constants.dbmNoExamine) },
        ];
    }

    // 
    static negPosToString(method) {
        switch (method) {
            case Constants.dbmNegative : return 'Negativní';
            case Constants.dbmPositive: return 'Pozitivní';
            case Constants.dbmNoExamine: return 'Nevyšetřeno';
            default: return '';
        }
    }

    // 
    static phosDrugList() {
        return [
            { value: Constants.dbmPhosDrugRenagel   , name: RDPUtils.phosDrugToString(Constants.dbmPhosDrugRenagel   ) },            
            { value: Constants.dbmPhosDrugCalciumCA , name: RDPUtils.phosDrugToString(Constants.dbmPhosDrugCalciumCA ) },            
            { value: Constants.dbmPhosDrugCalciumAC , name: RDPUtils.phosDrugToString(Constants.dbmPhosDrugCalciumAC ) },            
            { value: Constants.dbmPhosDrugAluminium , name: RDPUtils.phosDrugToString(Constants.dbmPhosDrugAluminium ) },            
            { value: Constants.dbmPhosDrugFosrenol  , name: RDPUtils.phosDrugToString(Constants.dbmPhosDrugFosrenol  ) },            
            { value: Constants.dbmPhosDrugZemplar   , name: RDPUtils.phosDrugToString(Constants.dbmPhosDrugZemplar   ) },            
            { value: Constants.dbmPhosDrugMimpara   , name: RDPUtils.phosDrugToString(Constants.dbmPhosDrugMimpara   ) },            
            { value: Constants.dbmPhosDrugVitaminD3 , name: RDPUtils.phosDrugToString(Constants.dbmPhosDrugVitaminD3 ) },            
        ];
    }

    // 
    static phosDrugToString(method) {
        switch (method) {
            case Constants.dbmPhosDrugRenagel   : return 'Vazače - Renagel';            
            case Constants.dbmPhosDrugCalciumCA : return 'Vazače - Calcium Carb.'; 
            case Constants.dbmPhosDrugCalciumAC : return 'Vazače - Calcium Acet.'; 
            case Constants.dbmPhosDrugAluminium : return 'Vazače - Aluminium hydroxid'; 
            case Constants.dbmPhosDrugFosrenol  : return 'Vazače - Fosrenol'; 
            case Constants.dbmPhosDrugZemplar   : return 'Zemplar'; 
            case Constants.dbmPhosDrugMimpara   : return 'Mimpara'; 
            case Constants.dbmPhosDrugVitaminD3 : return 'Vitamin D3'; 
            default: return '';
        }
    }
    
    // 
    static antihypDrugList() {
        return [
            { value: Constants.dbmAntihypDrugBeta     , name: RDPUtils.antihypDrugToString(Constants.dbmAntihypDrugBeta     ) },
            { value: Constants.dbmAntihypDrugAce      , name: RDPUtils.antihypDrugToString(Constants.dbmAntihypDrugAce      ) },
            { value: Constants.dbmAntihypDrugCaBlock  , name: RDPUtils.antihypDrugToString(Constants.dbmAntihypDrugCaBlock  ) },
            { value: Constants.dbmAntihypDrugCentral  , name: RDPUtils.antihypDrugToString(Constants.dbmAntihypDrugCentral  ) },
            { value: Constants.dbmAntihypDrugARB      , name: RDPUtils.antihypDrugToString(Constants.dbmAntihypDrugARB      ) },
            { value: Constants.dbmAntihypDrugDiuretika, name: RDPUtils.antihypDrugToString(Constants.dbmAntihypDrugDiuretika) },
            { value: Constants.dbmAntihypDrugAlfa     , name: RDPUtils.antihypDrugToString(Constants.dbmAntihypDrugAlfa     ) },
        ];
    }

    // 
    static antihypDrugToString(method) {
        switch (method) {
            case Constants.dbmAntihypDrugBeta     : return 'Betablokátory';
            case Constants.dbmAntihypDrugAce      : return 'ACEi';
            case Constants.dbmAntihypDrugCaBlock  : return 'Calcium blokátory';
            case Constants.dbmAntihypDrugCentral  : return 'Centrální sympatolytika';
            case Constants.dbmAntihypDrugARB      : return 'ARB';
            case Constants.dbmAntihypDrugDiuretika: return 'Diuretika';
            case Constants.dbmAntihypDrugAlfa     : return 'Alfablokátory';
            default: return '';
        }
    }
    
    // 
    static diabDrugList() {
        return [
            { value: Constants.dbmDiabDrugPad    , name: RDPUtils.diabDrugToString(Constants.dbmDiabDrugPad    ) },
            { value: Constants.dbmDiabDrugInsulin, name: RDPUtils.diabDrugToString(Constants.dbmDiabDrugInsulin) },
        ];
    }

    // 
    static diabDrugToString(method) {
        switch (method) {
            case Constants.dbmDiabDrugPad    : return 'PAD';
            case Constants.dbmDiabDrugInsulin: return 'Insulin';
            default: return '';
        }
    }
     
    // 
    static dyslipDrugList() {
        return [
            { value: Constants.dbmDyslipDrugStatiny, name: RDPUtils.dyslipDrugToString(Constants.dbmDyslipDrugStatiny) },
            { value: Constants.dbmDyslipDrugFibraty, name: RDPUtils.dyslipDrugToString(Constants.dbmDyslipDrugFibraty) },
        ];
    }

    // 
    static dyslipDrugToString(method) {
        switch (method) {
            case Constants.dbmDyslipDrugStatiny: return 'Léčba statiny';
            case Constants.dbmDyslipDrugFibraty: return 'Léčba fibráty';
            default: return '';
        }
    }

    // 
    static erytrHemList() {
        return [
            { value: Constants.dbmErytrHemNeg  , name: RDPUtils.erytrHemToString(Constants.dbmErytrHemNeg  ) },
            { value: Constants.dbmErytrHemMikro, name: RDPUtils.erytrHemToString(Constants.dbmErytrHemMikro) },
            { value: Constants.dbmErytrHemMakro, name: RDPUtils.erytrHemToString(Constants.dbmErytrHemMakro) },
        ];
    }

    // 
    static erytrHemToString(method) {
        switch (method) {
            case Constants.dbmErytrHemNeg: return 'Negativní';
            case Constants.dbmErytrHemMikro: return 'Mikro';
            case Constants.dbmErytrHemMakro: return 'Makro';
            default: return '';
        }
    }

    // 
    static hdlList() {
        return [
            { value: Constants.dbmHDLPgMl, name: RDPUtils.hdlToString(Constants.dbmHDLPgMl ) },            
            { value: Constants.dbmHDLPMolL, name: RDPUtils.hdlToString(Constants.dbmHDLPMolL) },            
        ];
    }

    // 
    static hdlToString(method) {
        switch (method) {
            case Constants.dbmHDLPgMl : return 'pg/ml';
            case Constants.dbmHDLPMolL: return 'pmol/l';
            default: return '';
        }
    }

    // 
    static diaTypeList() {
        return [
            { value: Constants.dbmDiaTypeType1, name: RDPUtils.diaTypeToString(Constants.dbmDiaTypeType1) },            
            { value: Constants.dbmDiaTypeType2, name: RDPUtils.diaTypeToString(Constants.dbmDiaTypeType2) },            
            { value: Constants.dbmDiaTypeOther, name: RDPUtils.diaTypeToString(Constants.dbmDiaTypeOther) },            
        ];
    }

    // 
    static diaTypeToString(method) {
        switch (method) {
            case Constants.dbmDiaTypeType1: return '1';
            case Constants.dbmDiaTypeType2: return '2';
            case Constants.dbmDiaTypeOther: return 'Jiný';
            default: return '';
        }
    }

    // 
    static nyhaTypeList() {
        return [
            { value: Constants.dbmNYHAI  , name: RDPUtils.nyhaToString(Constants.dbmNYHAI  ) },            
            { value: Constants.dbmNYHAII , name: RDPUtils.nyhaToString(Constants.dbmNYHAII ) },            
            { value: Constants.dbmNYHAIII, name: RDPUtils.nyhaToString(Constants.dbmNYHAIII) },            
            { value: Constants.dbmNYHAIV , name: RDPUtils.nyhaToString(Constants.dbmNYHAIV ) },            
        ];
    }

    // 
    static nyhaToString(method) {
        switch (method) {
            case Constants.dbmNYHAI  : return 'I';
            case Constants.dbmNYHAII : return 'II';
            case Constants.dbmNYHAIII: return 'III';
            case Constants.dbmNYHAIV : return 'IV';
            default: return '';
        }
    }

    // 
    static phospBindTypeList() {
        return [
            { value: Constants.dbmPhospBindCal        , name: RDPUtils.phospBindToString(Constants.dbmPhospBindCal        ) },
            { value: Constants.dbmPhospBindNoncalc    , name: RDPUtils.phospBindToString(Constants.dbmPhospBindNoncalc    ) },
            { value: Constants.dbmPhospBindCalcNoncalc, name: RDPUtils.phospBindToString(Constants.dbmPhospBindCalcNoncalc) },
        ];
    }

    // 
    static phospBindToString(method) {
        switch (method) {
            case Constants.dbmPhospBindCal        : return 'Kalciové';
            case Constants.dbmPhospBindNoncalc    : return 'Nekalciové';
            case Constants.dbmPhospBindCalcNoncalc: return 'Kalciové + nekalciové';
            default: return '';
        }
    }

    // 
    static monTypeList() {
        return [
            { value: Constants.dbmMonContinue , name: RDPUtils.monToString(Constants.dbmMonContinue ) },
            { value: Constants.dbmMonDead     , name: RDPUtils.monToString(Constants.dbmMonDead     ) },
            { value: Constants.dbmMonPDL      , name: RDPUtils.monToString(Constants.dbmMonPDL      ) },
            { value: Constants.dbmMonTX       , name: RDPUtils.monToString(Constants.dbmMonTX       ) },
            { value: Constants.dbmMonLost     , name: RDPUtils.monToString(Constants.dbmMonLost     ) },
            { value: Constants.dbmMonFncReturn, name: RDPUtils.monToString(Constants.dbmMonFncReturn) },
        ];
    }

    // 
    static monToString(method) {
        switch (method) {
            case Constants.dbmMonContinue : return 'Pokračuje';
            case Constants.dbmMonDead     : return 'Úmrtí';
            case Constants.dbmMonPDL      : return 'Zařazení do PDL';
            case Constants.dbmMonTX       : return 'Tx ledvin';
            case Constants.dbmMonLost     : return 'Ztracen z dalšího sledování';
            case Constants.dbmMonFncReturn: return 'Návrat funkcí';
            default: return '';
        }
    }

    // 
    static monDeadTypeList() {
        return [
            { value: Constants.dbmMonDeadCardSudde, name: RDPUtils.monDeadToString(Constants.dbmMonDeadCardSudde) },
            { value: Constants.dbmMonDeadCardOther, name: RDPUtils.monDeadToString(Constants.dbmMonDeadCardOther) },
            { value: Constants.dbmMonDeadOther    , name: RDPUtils.monDeadToString(Constants.dbmMonDeadOther    ) },
        ];
    }

    // 
    static monDeadToString(method) {
        switch (method) {
            case Constants.dbmMonDeadCardSudde: return 'Kardiovaskulární - náhlá smrt';
            case Constants.dbmMonDeadCardOther: return 'Kardiovaskulární - jiná';
            case Constants.dbmMonDeadOther    : return 'Jiná';
            default: return '';
        }
    }

    // 
    static centreTypeList() {
        return [
            { value: Constants.dbmCentreTypePrivate, name: RDPUtils.centreTypeToString(Constants.dbmCentreTypePrivate) },            
            { value: Constants.dbmCentreTypeState, name: RDPUtils.centreTypeToString(Constants.dbmCentreTypeState) },            
        ];
    }

    // 
    static centreTypeToString(method) {
        switch (method) {
            case Constants.dbmCentreTypePrivate: return 'Soukromé';            
            case Constants.dbmCentreTypeState: return 'Státní';            
            default: return '';
        }
    }

    // 
    static limitsTypeList() {
        return [
            { value: Constants.dbmLimitsStandard, name: RDPUtils.limitsTypeToString(Constants.dbmLimitsStandard) },
            { value: Constants.dbmLimitsUltraClean, name: RDPUtils.limitsTypeToString(Constants.dbmLimitsUltraClean) },
        ];
    }

    // 
    static limitsTypeToString(method) {
        switch (method) {
            case Constants.dbmLimitsStandard: return 'Standard';
            case Constants.dbmLimitsUltraClean: return 'Ultračisté';
            default: return '';
        }
    }

    // 
    static frequencyTypeList() {
        return [
            { value: Constants.dbmFreqDaily    , name: RDPUtils.frequencyTypeToString(Constants.dbmFreqDaily    ) },
            { value: Constants.dbmFreqWeekly   , name: RDPUtils.frequencyTypeToString(Constants.dbmFreqWeekly   ) },
            { value: Constants.dbmFreqMonthly  , name: RDPUtils.frequencyTypeToString(Constants.dbmFreqMonthly  ) },
            { value: Constants.dbmFreqQuarterly, name: RDPUtils.frequencyTypeToString(Constants.dbmFreqQuarterly) },
            { value: Constants.dbmFreqHalfYear , name: RDPUtils.frequencyTypeToString(Constants.dbmFreqHalfYear ) },
            { value: Constants.dbmFreqYearly   , name: RDPUtils.frequencyTypeToString(Constants.dbmFreqYearly   ) },
        ];
    }

    // 
    static frequencyTypeToString(method) {
        switch (method) {
            case Constants.dbmFreqDaily: return 'Denně';            
            case Constants.dbmFreqWeekly: return 'Týdně';            
            case Constants.dbmFreqMonthly: return 'Měsíčně';            
            case Constants.dbmFreqQuarterly: return 'Kvartálně';            
            case Constants.dbmFreqHalfYear: return 'Dvakrát ročně';            
            case Constants.dbmFreqYearly: return 'Ročně';            
            default: return '';
        }
    }

    // 
    static infectionTypeList() {
        return [
            { value: Constants.dbmInfectionHIV, name: RDPUtils.infectionTypeToString(Constants.dbmInfectionHIV) },            
            { value: Constants.dbmInfectionHCV, name: RDPUtils.infectionTypeToString(Constants.dbmInfectionHCV) },            
            { value: Constants.dbmInfectionHBS, name: RDPUtils.infectionTypeToString(Constants.dbmInfectionHBS) },            
        ];
    }

    // 
    static infectionTypeToString(method) {
        switch (method) {
            case Constants.dbmInfectionHIV: return 'HIV';
            case Constants.dbmInfectionHCV: return 'HCV';
            case Constants.dbmInfectionHBS: return 'HBS';
            default: return '';
        }
    }

    // 
    static isolationTypeList() {
        return [
            { value: Constants.dbmIsolationNoPatients, name: RDPUtils.isolationTypeToString(Constants.dbmIsolationNoPatients) },
            { value: Constants.dbmIsolationNone      , name: RDPUtils.isolationTypeToString(Constants.dbmIsolationNone      ) },
            { value: Constants.dbmIsolationMonito    , name: RDPUtils.isolationTypeToString(Constants.dbmIsolationMonito    ) },
            { value: Constants.dbmIsolationBed       , name: RDPUtils.isolationTypeToString(Constants.dbmIsolationBed       ) },
            { value: Constants.dbmIsolationShift     , name: RDPUtils.isolationTypeToString(Constants.dbmIsolationShift     ) },
            { value: Constants.dbmIsolationRoom      , name: RDPUtils.isolationTypeToString(Constants.dbmIsolationRoom      ) },
            { value: Constants.dbmIsolationPersonal  , name: RDPUtils.isolationTypeToString(Constants.dbmIsolationPersonal  ) },
        ];
    }

    // 
    static isolationTypeToString(method) {
        switch (method) {
            case Constants.dbmIsolationNoPatients: return 'Žádní pacienti této kategorie';
            case Constants.dbmIsolationNone: return 'Bez izolace';
            case Constants.dbmIsolationMonito: return 'Vyhrazený monitor';
            case Constants.dbmIsolationBed: return 'Vyhrazené lůžko';
            case Constants.dbmIsolationShift: return 'Vyhrazená směna';
            case Constants.dbmIsolationRoom: return 'Vyhrazený pokoj';
            case Constants.dbmIsolationPersonal: return 'Vyhrazený personál';
            default: return '';
        }
    }

    // 
    static waterModifTypeList() {
        return [
            { value: Constants.dbmWaterModifOsmosis  , name: RDPUtils.waterModifTypeToString(Constants.dbmWaterModifOsmosis  ) },
            { value: Constants.dbmWaterModifFiltMech , name: RDPUtils.waterModifTypeToString(Constants.dbmWaterModifFiltMech ) },
            { value: Constants.dbmWaterModifFiltCoal , name: RDPUtils.waterModifTypeToString(Constants.dbmWaterModifFiltCoal ) },
            { value: Constants.dbmWaterModifDeionizat, name: RDPUtils.waterModifTypeToString(Constants.dbmWaterModifDeionizat) },
            { value: Constants.dbmWaterModifUVRay    , name: RDPUtils.waterModifTypeToString(Constants.dbmWaterModifUVRay    ) },
            { value: Constants.dbmWaterModifSoftening, name: RDPUtils.waterModifTypeToString(Constants.dbmWaterModifSoftening) },
        ];
    }

    // 
    static waterModifTypeToString(method) {
        switch (method) {
            case Constants.dbmWaterModifOsmosis: return 'Reversní osmóza';
            case Constants.dbmWaterModifFiltMech: return 'Filtr mechanický';
            case Constants.dbmWaterModifFiltCoal: return 'Filtr s aktivním uhlím';
            case Constants.dbmWaterModifDeionizat: return 'Deionizace';
            case Constants.dbmWaterModifUVRay: return 'UV záření';
            case Constants.dbmWaterModifSoftening: return 'Změkčení';
            default: return '';
        }
    }

    // 
    static rdpDataTypeList() {
        return [
            { value: Constants.dbmRDPDataTypeCentre, name: RDPUtils.rdpDataTypeListToString(Constants.dbmRDPDataTypeCentre) },
            { value: Constants.dbmRDPDataTypePatients, name: RDPUtils.rdpDataTypeListToString(Constants.dbmRDPDataTypePatients) },            
        ];
    }

    // 
    static rdpDataTypeListToString(method) {
        switch (method) {
            case Constants.dbmRDPDataTypeCentre: return 'Data o středisku';
            case Constants.dbmRDPDataTypePatients: return 'Data o pacientech';            
            default: return '';
        }
    }

    // Vrací pole všech možných příčin úmrtí
    static deathCauseList() {
        return [
            { value: Constants.dbmDeathCausesCardio      , name: RDPUtils.deathCauseToString(Constants.dbmDeathCausesCardio      ) },
            { value: Constants.dbmDeathCausesInfection   , name: RDPUtils.deathCauseToString(Constants.dbmDeathCausesInfection   ) },
            { value: Constants.dbmDeathCausesMalignancies, name: RDPUtils.deathCauseToString(Constants.dbmDeathCausesMalignancies) },
            { value: Constants.dbmDeathCausesOther, name: RDPUtils.deathCauseToString(Constants.dbmDeathCausesOther) },
            { value: Constants.dbmDeathCausesUnknown, name: RDPUtils.deathCauseToString(Constants.dbmDeathCausesUnknown) },
        ];
    }

    // Vraci příčinu úmrtí jako text
    static deathCauseToString(event) {
        switch (event) {
            case Constants.dbmDeathCausesCardio: return 'Kardiovaskulární onemocnění';
            case Constants.dbmDeathCausesInfection: return 'Infekce';
            case Constants.dbmDeathCausesMalignancies: return 'Malignity';
            case Constants.dbmDeathCausesOther: return 'Jiné příčiny';
            case Constants.dbmDeathCausesUnknown: return 'Neznámá příčina';
            default: return '';
        }
    }

    // Vrací pole všech možných způsobů cety do RRT
    static rrtStartFromTypesList() {
        return [
            { value: Constants.dbmRRTStartFromTypesFromStreet, name: RDPUtils.rrtStartFromTypeToString(Constants.dbmRRTStartFromTypesFromStreet) },
            { value: Constants.dbmRRTStartFromTypesFromOwnAmbulance, name: RDPUtils.rrtStartFromTypeToString(Constants.dbmRRTStartFromTypesFromOwnAmbulance) },
            { value: Constants.dbmRRTStartFromTypesFromOtherAmbulance, name: RDPUtils.rrtStartFromTypeToString(Constants.dbmRRTStartFromTypesFromOtherAmbulance) },            
        ];
    }

    //
    static rrtStartFromTypeToString(event) {
        switch (event) {
            case Constants.dbmRRTStartFromTypesFromStreet: return 'Z ulice';
            case Constants.dbmRRTStartFromTypesFromOwnAmbulance: return 'Z vlastní ambulance';
            case Constants.dbmRRTStartFromTypesFromOtherAmbulance: return 'Z jiné ambulance';            
            default: return '';
        }
    }

    static prepareDataForQuarterCombo() {

        let result = [];

        for (let quarter = 1; quarter <= 4; quarter++) {
            result.push({ name: `${quarter}. kvartál`, value: quarter });
        }

        return result;
    }

    static prepareDataForYearCombo() {

        let result = [];

        for (let year = Constants.rdpFirstYearOfRDP; year <= RDPUtils.getEditYear(); year++) {
            result.push({ name: year, value: year });
        }

        return result;
    }
}