import React, { Component } from 'react';

import { withLoggedUserFormsContext } from '../WithLoggedUserFormsContext.js';
import WSClient from 'WSClient.js';
import Dictionaries from 'Dictionaries.js';
import CentreDropDownList from 'utils/CentreDropDownList.js'

import './CentreSelection.css';

class CentreSelection extends Component {

    // Změna kódu střediska
    changeCentreCode = (centreCode) => {        
        WSClient.ChangeAccessTokenCentre(centreCode);
        this.props.onSeletionDone();
    }

    render() {

        return (
            <div className="ContentBox">
                <div className="k-form-inline">
                    <label className="k-form-field">
                        <span>Zvolte středisko</span>
                        <CentreDropDownList
                            data={Dictionaries.centres.filter(w => w.code === WSClient.currentUser.data.centreCode || WSClient.currentUser.data.centres.includes(w.code))}
                            value={Dictionaries.centres.find(w => w.code === WSClient.currentCentreCode)}
                            onChange={(value) => this.changeCentreCode(value.code)}
                        />
                    </label>
                </div>
            </div>
        );
    }
}

export default withLoggedUserFormsContext(CentreSelection);
