import React, { Component } from 'react';

import { Menu } from '@progress/kendo-react-layout';
import { Popup } from '@progress/kendo-react-popup';

import { withRouter } from 'utils/WithRouter.js';

import './PopupMenu.css';

class PopupMenu extends Component {

    anchor = null;

    onSelect = (event) => {
        if (event.item.route !== undefined) {
            this.props.onMouseLeave();
            this.props.navigate(event.item.route);
        }

        if (event.item.link !== undefined) {
            window.open(event.item.link, "_blank")
        }
        else
            if (event.item.items !== undefined && event.item.items.length > 0)
                event.item.expanded = !event.item.expanded;
    }   

    render() {
        return (
            <React.Fragment>

                <Popup
                    offset={{ left: 70, top: 80 }}
                    show={this.props.show}
                    popupClass={'menuPopupContent userMenu'}
                >
                    <div
                        onMouseEnter={this.props.onMouseEnter}
                        onMouseLeave={this.props.onMouseLeave}
                    >
                        <span className="title">{this.props.title}</span>                        
                        <Menu                            
                            vertical={true}
                            style={{ display: 'inline-block' }}
                            items={this.props.tree.filter(w => w.visible).map(w => ({ ...w, cssClass: `${window.location.pathname.startsWith(w.route) ? 'selected': ''}`}))}
                            onSelect={this.onSelect}/>                        
                    </div>
                </Popup>
            </React.Fragment>
        );
    }
}

export default withRouter(PopupMenu);
