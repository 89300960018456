import { Component } from 'react';

import { withRouter } from 'utils/WithRouter.js';
import WSClient from '../WSClient.js';


class VersionCheck extends Component {

    //
    componentDidMount() {
        WSClient.addUserChangeListener(this.handleUserChange);
        this.handleUserChange();
    }

    //
    componentWillUnmount() {
        WSClient.removeUserChangeListener(this.handleStateChange);
    }

    // Tato metoda je volána ve chvíli, kdy dojde ke změně stavu uživatel přihlášen x nepřihlášen
    handleUserChange = () => {

        // Pokud uživatel není přihlášen, provedeme kontrolu změny verze
        // Po nahrání nové verze bude token vždy neplatný a po prvním přístupu na nějakou web service dojde k odhlášení uživatele i v klientské aplikaci
        
        // Načtení aktuální verze aplikace
        WSClient.getVersion((version) => {

            // Porovnání verzí, verze v backendu i v index.html musí být stejné             
            if (version !== window.cnnsVersion) {
                window.location.reload(true);
            }       
        });
    }

    // 
    render() {
        return '';
    }
}

export default withRouter(VersionCheck);
