import WSClient from 'WSClient.js';
import Constants from 'Constants.js';

// V této třídě jsou drženy načtené obsahy číselníků
// Aktualizace dat zatím není řešena
class Dictionaries {

    // Připojení k naslouchání stavu změn přihlášeného uživatele
    static static_constructor() {

        // Zde se drží uživatele, pro které jsou aktuálně načtené číselníky
        Dictionaries.AccessTokenGuid = '';

        // Chceme být upozorňováni na změny stavu přihlášeného uživatele
        WSClient.addUserChangeListener(Dictionaries.handleUserChange);
    }

    // Tato metoda je volána ve chvíli, kdy dojde ke změně stavu uživatel přihlášen x nepřihlášen
    static handleUserChange() {

        if (Dictionaries.AccessTokenGuid !== WSClient.currentUser.accessTokenGuid) {
            Dictionaries.AccessTokenGuid = WSClient.currentUser.accessTokenGuid;

            Dictionaries.centres = undefined;            
            Dictionaries.userGroups = undefined;            
            Dictionaries.atchs = undefined;            
        }
    }

    // Načtení obsahu číselníků, provádí se vždy po přihlášení zaměstnance
    static load(accessTokenGuid) {
        return [
            new Promise((resolve, reject) => {
                WSClient.loadDictionary(accessTokenGuid, '/Dictionary/GetCentreCollection', 'code').then(response => { Dictionaries.centres = response; resolve(); });
            }),        
            new Promise((resolve, reject) => {
                WSClient.loadDictionary(accessTokenGuid, '/Dictionary/GetUserGroupCollection', 'name').then(response => { Dictionaries.userGroups = response; resolve(); });
            }),    
            new Promise((resolve, reject) => {
                WSClient.loadDictionary(accessTokenGuid, '/Dictionary/GetATCHSCollection', 'code').then(response => { Dictionaries.atchs = response; resolve(); });
            }),    
        ];
    }

    // Zde se drží uživatele, pro které jsou aktuálně načtené číselníky
    static get AccessTokenGuid() {
        const v = window.sessionStorage.getItem("accessTokenGuid");
        return v ? JSON.parse(v) : undefined;                
    }

    static set AccessTokenGuid(value) {
        window.sessionStorage.setItem("accessTokenGuid", JSON.stringify(value))
    }

    // Seznam všech středisek
    static get centres() {
        const v = window.sessionStorage.getItem("centres");
        return v ? JSON.parse(v) : undefined;                
    }

    static set centres(value) {        
        window.sessionStorage.setItem("centres", JSON.stringify(value))
    }

    // Vrácení názvu střediska dle jeho kódu
    static findCentre(centreCode) {
        return Dictionaries.centres.find(w => w.code === centreCode);
    }

    // Seznam všech skupin uživatel
    static get userGroups() {
        const v = window.sessionStorage.getItem("userGroups");
        return v ? JSON.parse(v) : undefined;                
    }

    static set userGroups(value) {
        window.sessionStorage.setItem("userGroups", JSON.stringify(value))
    }


    // Vrácení skupiny dle id
    static findUserGroup(groupId) {
        return Dictionaries.userGroups.find(w => w.id === groupId);
    }


    // Seznam všech atchs
    static get atchs() {
        const v = window.sessionStorage.getItem("atchs");        
        return v ? JSON.parse(v) : undefined;                
    }

    static set atchs(value) {        
        window.sessionStorage.setItem("atchs", JSON.stringify(value))
    }


    // Vrácení atchs léku
    static findAtchs(code) {
        return Dictionaries.atchs.data.find(w => w.code === code);
    }
    
    // Vrací seznam ATCHS pro jednotlivé typy antihypertensiv
    static getAntihyperATCHS(type) {
        let codes = [];
        switch (type) {
            case Constants.dbmAntihypDrugAce: codes = [
                "C09AA01", "C09AA02", "C09AA03", "C09AA04", "C09AA05", "C09AA06", "C09AA07",
                "C09AA08", "C09AA09", "C09AA10", "C09AA11", "C09AA12", "C09AA13", "C09AA14",
                "C09AA15", "C09AA16", "C09BA02", "C09BA03", "C09BA04", "C09BA05", "C09BA06",
                "C09BA07", "C09BA08", "C09BB05", "C09BB10"
            ]; break;
            case Constants.dbmAntihypDrugARB: codes = [
            "C09CA01", "C09CA02", "C09CA03", "C09CA04", "C09CA05", "C09CA06", "C09CA07",
                "C09CA08", "C09DA01", "C09DA06", "C09DA08"
            ]; break;
            case Constants.dbmAntihypDrugBeta: codes = [
            "C07AA", "C07AA07", "C07AA17", "C07AB02", "C07AB03", "C07AB04", "C07AB05",
                "C07AB06", "C07AB07", "C07AB08", "C07AB09", "C07AB10", "C07AB11", "C07AB12",
                "C07AG02", "C07BB03", "C07BB07"
            ]; break;
            case Constants.dbmAntihypDrugDiuretika: codes = [
            "C03AA03", "C03BA", "C03BA04", "C03BA22", "C03CA01", "C03DA01", "C03DA04",
                "C03DB01", "C03EA01", "C03EA06", "C03EA06"
            ]; break;
            case Constants.dbmAntihypDrugCaBlock: codes = [
            "C08CA01", "C08CA02", "C08CA03", "C08CA04", "C08CA05", "C08CA06", "C08CA07",
                "C08CA08", "C08CA09", "C08CA10", "C08CA11", "C08CA12", "C08DA01", "C08DB01",
                "C09BB05", "C09BB10", "C10BX03"
            ]; break;
            case Constants.dbmAntihypDrugAlfa: codes = ["C02CA01", "C02CA04", "C02CA06", "C07AG02"]; break;
            case Constants.dbmAntihypDrugCentral: codes = ["C02AB01", "C02AC05", "C02AC06"]; break;           
            default: break;
        }

        return Dictionaries.atchs.data.filter(w => codes.includes(w.code));
    }
    
    // Vrací seznam ATCHS pro jednotlivé typy léčby Kalcio-fosfátový metabolismus 
    static getPhospATCHS(type) {
        let codes = [];
        switch (type) {
            case Constants.dbmPhosDrugZemplar: codes = [ "H05BX02" ]; break;
            case Constants.dbmPhosDrugMimpara: codes = [ "H05BX01" ]; break;
            case Constants.dbmPhosDrugCalciumAC: codes = [ "V03AE04", "A12AA12" ]; break;
            case Constants.dbmPhosDrugCalciumCA: codes = [ "A12AA04" ]; break;
            case Constants.dbmPhosDrugRenagel: codes = [ "V03AE02" ]; break;
            case Constants.dbmPhosDrugFosrenol: codes = [ "V03AE03" ]; break;
            case Constants.dbmPhosDrugAluminium: codes = [ "A02AD01" ]; break;
            case Constants.dbmPhosDrugVitaminD3: codes = ["A11CC03", "A11CC04", "A11CC05", "A11CC06", "A11CC07",]; break;            
            default: break;
        }

        return Dictionaries.atchs.data.filter(w => codes.includes(w.code));
    }

    // Vrací seznam ATCHS pro jednotlivé typy léčby Dyslipidémie
    static getDyslipidemiaATCHS(type) {
        let codes = [];
        switch (type) {
            case Constants.dbmDyslipDrugStatiny: codes = [
            "C10AA01", "C10AA02", "C10AA03", "C10AA04", "C10AA05", "C10AA06", "C10AA07",
                "C10BA02", "C10BX03"
            ]; break;
            case Constants.dbmDyslipDrugFibraty: codes = ["C10AB", "C10AB02", "C10AB05", "C10AB08"]; break;            
            default: break;
        }

        return Dictionaries.atchs.data.filter(w => codes.includes(w.code));
    }

    // Vrací seznam ATCHS pro jednotlivé typy léčby diabetes
    static getDiabetesATCHS(type) {
        let codes = [];
        switch (type) {
            case Constants.dbmDiabDrugPad: codes = [
            "A10BA02", "A10BB01", "A10BB02", "A10BB03", "A10BB04", "A10BB05",
                "A10BB06", "A10BB07", "A10BB08", "A10BB09", "A10BB10", "A10BB11",
                "A10BB12", "A10BD02", "A10BD03", "A10BF01", "A10BG02", "A10BG03",
                "A10BX02"
            ]; break;
            case Constants.dbmDiabDrugInsulin: codes = [
            "A10AB01", "A10AB02", "A10AB03", "A10AB04", "A10AB05", "A10AB06",
                "A10AC01", "A10AD01", "A10AD02", "A10AD03", "A10AD04", "A10AD05",
                "A10AE04", "A10AE05"
            ]; break;   
            default: break;
        }

        return Dictionaries.atchs.data.filter(w => codes.includes(w.code));
    }

}

export default Dictionaries;