import React, { Component } from 'react';

import { Outlet } from 'react-router-dom';

import { withRouter } from 'utils/WithRouter.js';
import WSClient, { erNoClientCertficate } from '../WSClient.js';

import Header from './Header.js';
import MainMenu from './MainMenu';

import './LoggedUserForms.css';
import constants from '../Constants.js';

import { LoggedUserFormsContext } from './LoggedUserFormsContext.js';



// Kontejner pro všechny komponenty, které se zobrazují pouze v případě, kdy je přihlášený uživatel
class LoggedUserForms extends Component {

    constructor(props) {
        super(props);

        this.state = {
            userLogged: WSClient.currentUser.accessTokenGuid !== '',
            width: 0,
            height: 0,
            headerInfoText: "",
            communicationError: "",
            breadCrumbNavigationTitle: "",
            breadCrumbNavigationText: "",
            topMenuButtonDisabled: {
                edit: [true, true, true],
                mail: null,   // null znamená, že celá příslušná skupina tlačítek nemá být vůbec vidět
                data: null
            },
            topMenuButtonHandler: []
        };
    }

    //
    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        WSClient.addUserChangeListener(this.handleUserChange);
        WSClient.addlastCommunicationResultListeners(this.handleCommChange);        
    }

    //
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
        WSClient.removeUserChangeListener(this.handleStateChange);
        WSClient.removeLastCommunicationResultListeners(this.handleCommChange);
    }

    //
    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth - 20, height: window.innerHeight - 170 });
    }

    // Tato metoda je volána ve chvíli změny stavu výsledku poslední komunikace se serverem
    handleCommChange = () => {

        // Vypsání chyby (erNoClientCertficate - Tato chyba se nevypisuje, zobrazuje se v hlavičce v informaci o použitém certifikátu)
        const info = WSClient.lastCommunicationResult.succeed || WSClient.lastCommunicationResult.result >= erNoClientCertficate ? "" : WSClient.lastCommunicationResult.errorMessage;
        this.setState({ communicationError: info });
    }

    // Tato metoda je volána ve chvíli, kdy dojde ke změně stavu uživatel přihlášen x nepřihlášen
    handleUserChange = () => {

        // Změna stavu přihlášení
        this.setState({
            userLogged: WSClient.currentUser.accessTokenGuid !== '',
        });

        // Pokud se přihlásil někdo z pojišťovny, pošleme ho rovnou na jeho stránku
        if (WSClient.isCurrentUserInsuranceCompany)
            this.props.history.push('/insComp/patients');
    }

    // Tato metoda je volána pro změnu drobečkové navigace
    handleBreadCrumbNavigationChange = (title, text) => {
        this.setState({ breadCrumbNavigationTitle: title, breadCrumbNavigationText: text });
    }

    // Tato metoda je volána pro změnu textu v hlavičce webu
    handleHeaderInfoTextChange = (text) => {
        this.setState({ headerInfoText: text });
    }

    // Tato metoda je volána pro změnu stavu tlačítek menu v hlavičce (new, edit, delete)
    handleTopMenuButtonDisabledChange = (newState) => {
        this.setState({ topMenuButtonDisabled: newState });
    }

    // Tato metoda je volána pro zaregistrování obsluhy tlačítek menu v hlavičce (new, edit, delete)
    addTopMenuButtonHandler = (handler) => {
        this.setState((state) => {
            let t = this.state.topMenuButtonHandler;
            t.push(handler);
            return { topMenuButtonHandler: t };
        });
    }

    // Tato metoda je volána pro odregistrování obsluhy tlačítek menu v hlavičce (new, edit, delete)
    removeTopMenuButtonHandler = () => {
        let t = this.state.topMenuButtonHandler;
        t.shift();
        this.setState({ topMenuButtonHandler: t });
    }

    // Zobrazuje se pouze v případě, když je někdo přihlášen
    render() {

        return (this.state.userLogged && this.props.secondFactor === constants.sfNone &&
            <LoggedUserFormsContext.Provider value={{
                locale: this.props.locale,
                headerInfoText : this.state.headerInfoText, 
                breadCrumbNavigationTitle : this.state.breadCrumbNavigationTitle, 
                breadCrumbNavigationText : this.state.breadCrumbNavigationText, 
                topMenuButtonDisabled : this.state.topMenuButtonDisabled, 
                topMenuButtonHandler: this.state.topMenuButtonHandler,     
                onBreadCrumbNavigationChange : this.handleBreadCrumbNavigationChange,
                onTopMenuButtonDisabledChange : this.handleTopMenuButtonDisabledChange,
                addTopMenuButtonHandler : this.addTopMenuButtonHandler,
                removeTopMenuButtonHandler : this.removeTopMenuButtonHandler,                        
            }}>
                <div id="MainMenu">
                    <MainMenu />
                </div>
                <div id="Headline">
                    <Header />
                </div>
                <div id="Middle">
                    <div className="Content">
                        {this.state.communicationError &&
                            <div id="errorBox" className="cbox">
                                <div>Došlo k chybě</div>
                                <div>{this.state.communicationError}</div>
                            </div>
                        }
                        <Outlet />
                    </div>
                </div>
            </LoggedUserFormsContext.Provider>
        );

    }
}

export default withRouter(LoggedUserForms);
