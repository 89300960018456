import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';

import { Dialog } from '@progress/kendo-react-dialogs';

import Loading from 'images/Loading.gif';

import App from './App';

import './index.css';

//import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';


const UserHomePage = React.lazy(() => import('./components/pages/UserHomePage.js'));
const UserSettingPage = React.lazy(() => import('./components/pages/UserSetting.js'));
const CertificateRegistrationPage = React.lazy(() => import('./components/pages/CertificateRegistration.js'));
const SmsValidationPage = React.lazy(() => import('./components/pages/SmsValidation.js'));

const UserGroupListPage = React.lazy(() => import('./components/pages/admin/UserGroupList'));
const UserListPage = React.lazy(() => import('./components/pages/admin/UserList'));
const CentreListPage = React.lazy(() => import('./components/pages/admin/CentreList'));
const Utils = React.lazy(() => import('./components/pages/admin/Utils'));
const ReportsExport = React.lazy(() => import('./components/pages/admin/ReportsExport'));
const ReportsDirectDataExport = React.lazy(() => import('./components/pages/admin/ReportsDirectDataExport'));
const Log = React.lazy(() => import('./components/pages/admin/Log'));
const Statistics = React.lazy(() => import('./components/pages/admin/Statistics'));

const TreatQualityParam = React.lazy(() => import('./components/pages/statistic/TreatQualityParam'));
const ActualNationalDataReport = React.lazy(() => import('./components/pages/statistic/ActualNationalDataReport'));
const CentreReport = React.lazy(() => import('./components/pages/statistic/CentreReport'));

const MailPage = React.lazy(() => import('./components/pages/mail/Mail.js'));

const Patients = React.lazy(() => import('./components/pages/data/Patients.js'));
const CentreSummary = React.lazy(() => import('./components/pages/data/CentreSummary.js'));
const Import = React.lazy(() => import('./components/pages/data/Import.js'));
const Centre = React.lazy(() => import('./components/pages/data/Centre.js'));
const Summary2021 = React.lazy(() => import('./components/pages/data/summary2021/Summary2021.js'));
const DeathCause2022 = React.lazy(() => import('./components/pages/data/deathCause2022/DeathCause2022.js'));
const Summary = React.lazy(() => import('./components/pages/data/summary/Summary.js'));

const MBMessageList = React.lazy(() => import('./components/pages/messageBoard/MBMessageList.js'));

const Documents = React.lazy(() => import('./components/pages/Documents.js'));

const InsCompPatients = React.lazy(() => import('./components/pages/insComp/Patients.js'));

const YearbookEditor = React.lazy(() => import('./components/pages/yearbook/Yearbook.js'));

const loadingDlg = <Dialog className="loadingDlg"><img src={Loading} alt="Prob�h� na��t�n� k�du aplikace ze serveru" /></Dialog>;


// V�echno routov�n� je obslou�eno zde
const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<App />}>
            <Route index element={
                <React.Suspense fallback={loadingDlg}>
                    <UserHomePage />
                </React.Suspense>
            } />            
            <Route path="/userSetting" element={
                <Suspense fallback={loadingDlg}>
                    <UserSettingPage />
                </Suspense>
            } />
            <Route path="/certificateRegistration" element={
                <Suspense fallback={loadingDlg}>
                    <CertificateRegistrationPage />
                </Suspense>
            } />
            <Route path="/smsValidation" element={
                <Suspense fallback={loadingDlg}>
                    <SmsValidationPage />
                </Suspense>
            } />
            <Route path="/data/patients/:centre?/:year?/:quarter?" element={
                <Suspense fallback={loadingDlg}>
                    <Patients />
                </Suspense>
            } />
            <Route path="/data/summary2021" element={
                <Suspense fallback={loadingDlg}>
                    <Summary2021 />
                </Suspense>
            } />
            <Route path="/data/summary" element={
                <Suspense fallback={loadingDlg}>
                    <Summary />
                </Suspense>
            } />
            <Route path="/data/deathCause2022" element={
                <Suspense fallback={loadingDlg}>
                    <DeathCause2022 />
                </Suspense>
            } />
            <Route path="/data/centreData" element={
                <Suspense fallback={loadingDlg}>
                    <Centre />
                </Suspense>
            } />
            <Route path="/data/centreSummary" element={
                <Suspense fallback={loadingDlg}>
                    <CentreSummary />
                </Suspense>
            } />
            <Route path="/data/import" element={
                <Suspense fallback={loadingDlg}>
                    <Import />
                </Suspense>
            } />
            <Route path="/mail" element={
                <Suspense fallback={loadingDlg}>
                    <MailPage />
                </Suspense>
            } />
            <Route path="/stat/treatQualityParam" element={
                <Suspense fallback={loadingDlg}>
                    <TreatQualityParam />
                </Suspense>
            } />
            <Route path="/stat/actualNationalDataReport" element={
                <Suspense fallback={loadingDlg}>
                    <ActualNationalDataReport />
                </Suspense>
            } />
            <Route path="/stat/centreReport" element={
                <Suspense fallback={loadingDlg}>
                    <CentreReport />
                </Suspense>
            } />
            <Route path="/admin/users" element={
                <Suspense fallback={loadingDlg}>
                    <UserListPage />
                </Suspense>
            } />
            <Route path="/admin/userGroups" element={
                <Suspense fallback={loadingDlg}>
                    <UserGroupListPage />
                </Suspense>
            } />
            <Route path="/admin/centres" element={
                <Suspense fallback={loadingDlg}>
                    <CentreListPage />
                </Suspense>
            } />
            <Route path="/admin/utils" element={
                <Suspense fallback={loadingDlg}>
                    <Utils />
                </Suspense>
            } />
            <Route path="/admin/reportsExport" element={
                <Suspense fallback={loadingDlg}>
                    <ReportsExport />
                </Suspense>
            } />
            <Route path="/admin/reportsDirectDataExport" element={
                <Suspense fallback={loadingDlg}>
                    <ReportsDirectDataExport />
                </Suspense>
            } />
            <Route path="/admin/log" element={
                <Suspense fallback={loadingDlg}>
                    <Log />
                </Suspense>
            } />
            <Route path="/admin/stat" element={
                <Suspense fallback={loadingDlg}>
                    <Statistics />
                </Suspense>
            } />
            <Route path="/insComp/patients" element={
                <Suspense fallback={loadingDlg}>
                    <InsCompPatients />
                </Suspense>
            } />
            <Route path="/documents" element={
                <Suspense fallback={loadingDlg}>
                    <Documents />
                </Suspense>
            } />
            <Route path="/messageBoard" element={
                <Suspense fallback={loadingDlg}>
                    <MBMessageList />
                </Suspense>
            } />
            <Route path="/yearbook" element={
                <Suspense fallback={loadingDlg}>
                    <YearbookEditor />
                </Suspense>
            } />
        </Route>
    ));





const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <RouterProvider router={router} fallbackElement={loadingDlg} />
);


unregister();
//registerServiceWorker();

// Na server service worker zp�soboval podivn� cacheov�n� v�sledk� vol�n� zobrazen� logu, p��lohy emailu a souhlasu pacienta.
// Vracela se m�sto obsahu hlavn� str�nka.