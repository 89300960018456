import React, { Component } from 'react';

import { Ripple } from '@progress/kendo-react-ripple';
import { IntlProvider, load, loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import numbers from "cldr-numbers-full/main/cs/numbers.json";
import caGregorian from "cldr-dates-full/main/cs/ca-gregorian.json";
import dateFields from "cldr-dates-full/main/cs/dateFields.json";
import localCurrency from "cldr-numbers-full/main/cs/currencies.json";
import timeZoneNames from "cldr-dates-full/main/cs/timeZoneNames.json";

import { withRouter } from 'utils/WithRouter.js';
import { initYup } from './utils/Utils.js';
import WSClient from './WSClient.js';
import constants from './Constants.js';

import VersionCheck from './components/VersionCheck.js';
import Login from './components/Login.js';
import LoginSecondFactorSMS from './components/LoginSecondFactorSMS.js';
import LoginSecondFactorQuestion from './components/LoginSecondFactorQuestion.js';
import LoginSecondFactorRegister from './components/LoginSecondFactorRegister.js';
import LoggedUserForms from './components/LoggedUserForms.js';
import LoadingDlg from './components/LoadingDlg.js';

import "@progress/kendo-theme-bootstrap/dist/all.css";
import 'bootstrap/dist/css/bootstrap.css';
import './custom.css'

import csMessages from './data/cs-CZ.json';


// Lokalizace
load(
    likelySubtags,
    currencyData,
    localCurrency,
    weekData,
    numbers,
    caGregorian,
    dateFields,
    timeZoneNames
);

loadMessages(csMessages, 'cs');

// Inicilizace
WSClient.static_constructor();
initYup();


class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            locale: 'cs',
            secondFactor: constants.sfNone,
            loadingDlgText: null,
        };

        WSClient.setLoadingDlgText = this.setLoadingDlgText;
    }    

    //
    setLoadingDlgText = (loadingDlgText) => {
        this.setState({ loadingDlgText });
    }

    render() {

        return (
            <LocalizationProvider language={this.state.locale}>
                <IntlProvider locale={this.state.locale}>
                    <div className="App">
                        <Ripple>
                            <VersionCheck/>
                            <Login setSecondFactor={(secondFactor) => this.setState({ secondFactor })} />
                            <LoginSecondFactorSMS secondFactor={this.state.secondFactor} setSecondFactor={(secondFactor) => this.setState({ secondFactor })} />
                            <LoginSecondFactorQuestion secondFactor={this.state.secondFactor} setSecondFactor={(secondFactor) => this.setState({ secondFactor })} />
                            <LoginSecondFactorRegister secondFactor={this.state.secondFactor} setSecondFactor={(secondFactor) => this.setState({ secondFactor })} />
                            <LoggedUserForms locale={this.state.locale} secondFactor={this.state.secondFactor} />
                        </Ripple>
                        <LoadingDlg text={this.state.loadingDlgText} />
                    </div>
                </IntlProvider>
            </LocalizationProvider>
        );
    }
}

export default withRouter(App);
