import React, { Component } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';

import WSClient from '../WSClient.js';

import Loading from 'images/Loading.gif';
import './LoadingDlg.css';

export default class LoadingDlg extends Component {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
        };
    }

    //
    componentDidMount() {

        WSClient.addActiveCallsListeners(this.handleActiveCallsChange);
    }

    //
    componentWillUnmount() {
        WSClient.removeActiveCallsListeners(this.handleActiveCallsChange);
    }

    handleActiveCallsChange = () => {

        this.setState({
                visible: WSClient.activeCalls.length > 0
            });        
    }


    render() {
        return (
            <React.Fragment>
                {this.state.visible && <Dialog className="loadingDlg">
                    <img src={Loading} alt="Probíhá načítání dat ze serveru" />
                    {this.props.text && <p>{this.props.text}</p>}
                </Dialog>}
            </React.Fragment>
        );
    }
}


