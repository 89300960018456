import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

import { withRouter } from 'utils/WithRouter.js';
import WSClient, { erNoClientCertficate, erUserHasNoActiveCertficate, erClientCertificateNotMatchRegisteredOne } from '../WSClient.js';
import constants from '../Constants.js';

import './Login.css';

class Login extends Component {

    constructor(props) {
        super(props);

        // Stav
        this.state = {
            userLogged: WSClient.currentUser.accessTokenGuid !== '',
            errorMsg: this.detectIE() ? 'Používáte nepodporovaný prohlížeč! Doporučujeme Vám použít prohlížeč Google Chrome.' : '',
        };
    }

    //
    componentDidMount() {
        WSClient.addUserChangeListener(this.handleUserChange);
        WSClient.addlastCommunicationResultListeners(this.handleCommChange);

        this.checkUrl();
    }

    //
    componentWillUnmount() {
        WSClient.removeUserChangeListener(this.handleStateChange);
        WSClient.removeLastCommunicationResultListeners(this.handleCommChange);
    }

    // Tato metoda je volána ve chvíli změny stavu výsledku poslední komunikace se serverem
    handleCommChange = () => {

        // Tato chyba se nevypisuje, zobrazuje se v hlavičce v informaci o použitém certifikátu
        if (WSClient.lastCommunicationResult.result < erNoClientCertficate) {

            this.setState({
                errorMsg: WSClient.lastCommunicationResult.errorMessage
            });
        }
    }

    // Tato metoda je volána ve chvíli, kdy dojde ke změně stavu uživatel přihlášen x nepřihlášen
    handleUserChange = () => {

        this.checkUrl();

        this.setState({
            userLogged: WSClient.currentUser.accessTokenGuid !== '',
        });
    }

    // Pokud není nikdo přihlášen, vracíme se vždy do rootu
    checkUrl() {
        if (WSClient.currentUser.accessTokenGuid === '')
            this.props.navigate('/');
    }

    // Reakce na vyplnění přihlašovacích údajů
    login(values, callback) {

        // Pokus o přihlášení        
        WSClient.createAccessToken(values.userName, values.password, null, (guid) => {

            // Pokud se přihlášení podařilo, ale zároveň není uživatel ověřen pomocí certifikátu, zobrazíme dialog pro ověření pomocí SMS
            if (guid) {
                let sf;

                switch (WSClient.currentUser.clientCertificateState) {
                    case erNoClientCertficate: sf = constants.sfSMS; break;
                    case erUserHasNoActiveCertficate: sf = constants.sfNotActive; break;
                    case erClientCertificateNotMatchRegisteredOne: sf = constants.sfNotMatch; break;
                    default: sf = constants.sfNone; break;
                }

                this.props.setSecondFactor(sf);
            }

            callback(guid);
        });
    }

    detectIE() {
        const ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        const msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        const trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        const edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }

    // Dialog se zobrazuje pouze v případě, když není nikdo přihlášen
    render() {
        return this.state.userLogged ? '' :
            <Formik
                initialValues={{
                    userName: '',
                    password: ''
                }}
                validationSchema={Yup.object().shape({
                    userName: Yup.string().required(constants.valRequired),
                    password: Yup.string().required(constants.valRequired)
                })}
                onSubmit={(values, { setSubmitting }) => {
                    this.login(values, (guid) => {
                        if (!guid)
                            setSubmitting(false);
                    });
                }}
            >
                {props =>
                (
                    <div className="loginTable">
                            <div>
                                <div>
                                    <div className='loginHeader'>
                                        <div>
                                            <div className="title">Přihlásit se do RDP</div>
                                            <div className="subTitle">Vstup pro registrované DS ČR</div>
                                        </div>                                        
                                    </div>
                                    <div className='loginBody'>
                                        <Form className="loginForm k-form-inline">
                                            <fieldset>
                                                <label className="k-form-field">
                                                    <Field className={props.touched.userName && props.errors.userName ? "k-textbox valInputError" : "k-textbox"}
                                                        placeholder="Uživatelské jméno"
                                                        name="userName"
                                                        disabled={props.isSubmitting}
                                                        autoFocus
                                                    />
                                                </label>
                                                {props.touched.userName && props.errors.userName && <p className="valError">{props.errors.userName}</p>}
                                                <label className="k-form-field">
                                                    <Field className={props.touched.password && props.errors.password ? "k-textbox valInputError" : "k-textbox"}
                                                        placeholder="Heslo"
                                                        type="password"
                                                        name="password"
                                                        disabled={props.isSubmitting}
                                                    />
                                                </label>
                                                {props.touched.password && props.errors.password && <p className="valError">{props.errors.password}</p>}
                                                <button type="submit" className="k-button k-primary" disabled={props.isSubmitting || this.detectIE() }>Přihlásit se</button>
                                            </fieldset>
                                        </Form>
                                        <p className="valError">{this.state.errorMsg}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </Formik>
            ;
    }
}

export default withRouter(Login);
